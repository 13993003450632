import React, { useState } from 'react';
import LogoV2 from '../assets/LogoHSV2.png';
import './css/HomePage.css';

const HomePage = () => {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  return (
    <div className="home-page">
      <header className="header">
        <div className="logo">
          <img src={LogoV2} alt="Logo" />
        </div>
        <div className={`burger-menu ${menuActive ? 'active' : ''}`} onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <nav className={`nav ${menuActive ? 'active' : ''}`}>
          <ul>
            <li><a href="/DownloadPage">Download</a></li>
            <li><a href="/RegistrationForm">Inscriptions</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </nav>
      </header>
      <section className="hero">
        <div className="hero-content">
          <h1>Bienvenue sur Happy score</h1>
          <p>Améliorez votre jeu de tennis avec <br /> notre application de scoring innovante</p>
          <button className="cta-btn">Découvrez HappyScore</button>
        </div>
      </section>
      <section className="features">
        <h2>Nos fonctionnalités</h2>
        <ul>
          <li>
            <i className="fas fa-chart-bar"></i>
            <h3>Suivi des scores</h3>
            <p>Suivez vos scores et améliorez votre jeu</p>
          </li>
          <li>
            <i className="fas fa-trophy"></i>
            <h3>Mode compétition</h3>
            <p>Concurrez avec vos amis et d'autres joueurs en temps réel</p>
          </li>
          <li>
            <i className="fas fa-coach"></i>
            <h3>Conseils d'entraînement personnalisés</h3>
            <p>Obtenez des conseils d'entraînement personnalisés pour améliorer votre jeu</p>
          </li>
        </ul>
      </section>
      <footer className="footer">
        <p>&copy; 2023 HappyScore. Tous droits réservés.</p>
      </footer>
    </div>
  );
};

export default HomePage;
