import React, { useState } from 'react';
import LogoV2 from '../assets/LogoHSV2.png';
import './css/RegistrationForm.css';

const RegistrationForm = () => {
  const [formState, setFormState] = useState({
    nom: '',
    prenom: '',
    username: '',
    password: '',
    email: '',
    licence: '',
  });

  const [errors, setErrors] = useState({});
  const [apiResponse, setApiResponse] = useState(null); // Pour afficher la réponse API ou un message

  const validateForm = () => {
    let formErrors = {};
    
    if (!formState.nom) formErrors.nom = "Le nom est requis";
    if (!formState.prenom) formErrors.prenom = "Le prénom est requis";
    if (!formState.email || !/\S+@\S+\.\S+/.test(formState.email)) formErrors.email = "Email invalide";
    // Ajoute d'autres validations ici

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.id]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (validateForm()) {
      try {
        const response = await fetch(`https://dev1.yokolong.org/inscription/subscription`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 'azerty',
          },
          body: JSON.stringify({
            licence: formState.licence,
            nom: formState.nom,
            prenom: formState.prenom,
            email: formState.email,
            username: formState.email,
            password: formState.password,
          }),
        });

      

        if (response.ok) {
          const data = await response.json();
          setApiResponse('Inscription réussie!');
          console.log('API Response:', data);
        } else {
          const errorData = await response.json();
          setApiResponse('Erreur lors de l\'inscription');
          console.error('Erreur API:', errorData);
        }
      } catch (error) {
        console.error('Erreur lors de l\'envoi à l\'API:', error);
        setApiResponse('Une erreur s\'est produite.');
      }
    } else {
      console.log('Erreur dans le formulaire', errors);
    }
  };

  return (
    <div className="inscription-form">
      <div className="logo-container">
        <img src={LogoV2} alt="Logo" />
      </div>
      <h2 className="title">INSCRIPTION</h2>
      <form onSubmit={handleSubmit}>
        {['nom', 'prenom', 'username', 'password', 'email', 'licence'].map((field) => (
          <div className="form-group" key={field}>
            <label htmlFor={field}>{field.charAt(0).toUpperCase() + field.slice(1)}</label>
            <input
              type={field === 'password' ? 'password' : 'text'}
              id={field}
              value={formState[field]}
              onChange={handleChange}
              placeholder={`Entrez votre ${field}`}
            />
            {errors[field] && <span className="error">{errors[field]}</span>}
          </div>
        ))}
        <button className="submitButton" type="submit">S'inscrire</button>
      </form>
      {apiResponse && <p className="apiResponse">{apiResponse}</p>} {/* Affiche la réponse API */}
    </div>
  );
};

export default RegistrationForm;
